import styles from '../../styles/components/app/Post.module.css';

import { HiOutlineHeart, HiOutlineAnnotation } from 'react-icons/hi';

import $ from 'jquery';

interface PostProps {
  author: {
    id: string;
    username: string;
    realname: string;
    avatar: string;
  };
  content: string;
  image: string;
  likes: number;
  comments: number;
}

export function Post(props: PostProps) {

  let touchTime = 0;
  $(`.like`).on("click", function (e: any) {
    if (touchTime === 0) {
      touchTime = new Date().getTime();
    } else {
      if (((new Date().getTime()) - touchTime) < 400) {
        e.currentTarget.classList.add(`${styles.active}`);

        setTimeout(() => {
          e.currentTarget.classList.remove(`${styles.active}`);
        }, 1000);
        touchTime = 0;
      } else {
        touchTime = new Date().getTime();
      }
    }
  });

  return (
    <div className={styles.postContainer}>
      <div className={`${styles.postImage} like`}>
        <img src={props.image} alt="Post" />
        <div className={styles['instagram-heart']}></div>
      </div>
      <div className={styles.postDetails}>
        <div className={styles.author}>
          <img src={props.author.avatar} alt={props.author.username} />
          <span>{props.author.realname}</span>
        </div>
        <div className={styles.otherDetails}>
          <div className="likes">
            <HiOutlineHeart size="1.55rem" color="var(--dar-blue)" />
            <span>{props.likes}</span>
          </div>
          <div className="comments">
            <HiOutlineAnnotation size="1.55rem" color="var(--dar-blue)" />
            <span>{props.comments}</span>
          </div>
        </div>
      </div>
    </div>
  )
}