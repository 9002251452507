import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { UserContext } from '../../contexts/UserContext';

import { Menu } from '../../components/app/Menu';
import { Header } from '../../components/app/Header';

import { Sidebar } from '../../components/app/Sidebar';

import styles from '../../styles/pages/app/Profile.module.css';
import { apiWithHeader } from '../../services/api';
import { Post } from '../../components/app/Post';
import { Modal } from '../../components/app/Modal';
import { IoClose } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

export function Profile() {

  const { userId } = useParams() as any;
  const [userData, setUserData] = useState([] as any);

  const { id, isOpened, gridTemplate, isSoonModalOpened, handleToggleSoonModal } = useContext(UserContext);
  const userProfileId = userData.id;
  const { avatar, realname, username, hobbies_number, followers_number, followings_number, posts_number } = userData;

  const [avatarPhoto, setAvatarPhoto] = useState(<img src={`https://api.meety.pt:8012/uploads/avatars/default.png`} alt={realname} />);

  const [posts, setPosts] = useState([]);

  const [following, setFollowing] = useState(false);
  const [followingButton, setFollowingButton] = useState(<button type="button" onClick={follow} className={styles.followButton}>Seguir</button>);

  const history = useHistory();


  function follow() {
    apiWithHeader.post('follow', {
      userToFollow: userId
    }).then((res: any) => {
      setFollowing(true);
    });
  }

  function unfollow() {
    apiWithHeader.post('unfollow', {
      userToUnfollow: userId
    }).then((res: any) => {
      setFollowing(false);
    });
  }

  useEffect(() => {
    async function fetchData() {
      await apiWithHeader.get('me').then(userData => {
        userData.data.data.followings.forEach((following: any) => {
          if (following.id === userId) {
            setFollowing(true);
          }
        });
      });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (following) {
      setFollowingButton(<button type="button" onClick={unfollow} className={styles.unfollowButton}>Deixar de seguir</button>)
    } else {
      setFollowingButton(<button type="button" onClick={follow} className={styles.followButton}>Seguir</button>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [following]);

  useEffect(() => {
    apiWithHeader.get(`users/${userId}`).then((res: any) => {
      setUserData(res.data.data);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userId === id && history.push('/app/me');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (avatar) {
      setAvatarPhoto(<img src={avatar.url} alt={realname} />);
    }
  }, [avatar, realname]);

  useEffect(() => {
    apiWithHeader.get(`users/${userProfileId}/posts`).then((res: any) => {
      setPosts(res.data.postsArr);
    });
  }, [userProfileId]);

  return (
    <div className={styles.app} style={{ gridTemplateColumns: gridTemplate }}>
      <Menu />

      <Header />

      {isSoonModalOpened && (
        <Modal title="Brevemente">
          <div className={styles.closeModal} onClick={handleToggleSoonModal}>
            <IoClose size="2rem" color="#ff0033" />
          </div>
          <RiErrorWarningLine size="4rem" color="var(--green)" />
        </Modal>
      )}

      <div className={styles.profileContainer}>
        <div className={styles.profileDetails}>
          <div className={styles.personalDetails}>
            {avatarPhoto}
            <div className={styles.personalDetailsText}>
              <h1>{realname}</h1>
              <span>@{username}</span>
            </div>
          </div>

          <div className={styles.otherDetails}>
            <div className={styles.otherDetailsTexts}>
              <div className={styles.otherDetailsText}>
                <span>Seguidores</span>
                <span>{followers_number}</span>
              </div>
              <div className={styles.otherDetailsText}>
                <span>A Seguir</span>
                <span>{followings_number}</span>
              </div>
              <div className={styles.otherDetailsText}>
                <span>Hobbies</span>
                <span>{hobbies_number}</span>
              </div>
              <div className={styles.otherDetailsText}>
                <span>Posts</span>
                <span>{posts_number}</span>
              </div>
            </div>
            {followingButton}
          </div>
        </div>

        <div className={styles.postsDetails}>
          <div className={styles.postsContainer}>
            <h1>Posts</h1>
            <div className={styles.posts}>
              {posts.map((post: any) => {
                return (
                  <Post
                    key={post.id}
                    author={post.author}
                    comments={21}
                    likes={post.likes}
                    content={post.content}
                    image={post.file_url}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>

      {isOpened && <Sidebar />}
    </div>
  );
}