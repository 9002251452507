import { Link, NavLink } from 'react-router-dom';

import { AiOutlineHome } from 'react-icons/ai';
import {
  HiOutlineBell,
  HiOutlineAnnotation,
  HiOutlineSupport,
  HiSearch,
  HiOutlinePlusCircle
} from 'react-icons/hi';

import styles from '../../styles/components/app/Menu.module.css';

import logo from '../../images/logo.svg';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { FiLogOut } from 'react-icons/fi';

export function Menu() {
  const { avatar, realname, handleTogglePostModal, handleToggleSoonModal } = useContext(UserContext);

  const [avatarPhoto, setAvatarPhoto] = useState(<img src={`https://api.meety.pt:8012/uploads/avatars/default.png`} alt={realname} />);

  useEffect(() => {
    if (avatar) {
      setAvatarPhoto(<img src={avatar.url} alt={realname} />);
    }
  }, [avatar, realname]);

  return (
    <div className={styles.menuContainer}>
      <div className={styles.logo}>
        <Link to="/app">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <ul>
        <li>
          <NavLink to="/app" exact activeClassName={styles.active}>
            <AiOutlineHome size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        <li className={styles.toMobile}>
          <NavLink to="#" onClick={handleToggleSoonModal} >
            <HiSearch size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        <li>
          <NavLink to="#" onClick={handleToggleSoonModal}>
            <HiOutlineBell size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        <li className={styles.toMobile}>
          <div className={styles.moreBtn} onClick={handleTogglePostModal}>
            <HiOutlinePlusCircle size="2.06rem" color="#fff" />
          </div>
        </li>
        <li>
          <NavLink to="/app/chat" activeClassName={styles.active}>
            <HiOutlineAnnotation size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        <li>
          <NavLink to="#" onClick={handleToggleSoonModal}>
            <HiOutlineSupport size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/app/settings" activeClassName={styles.active}>
            <HiOutlineCog size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/logout" activeClassName={styles.active}>
            <FiLogOut size="1.9rem" color="#94A2B0" />
          </NavLink>
        </li>
        <li className={styles.toMobile}>
          <Link to="/app/me" className={styles.profile}>
            {avatarPhoto}
            <span className={styles.status}></span>
          </Link>
        </li>
      </ul>

      <Link to="/app/me" className={styles.profile}>
        {avatarPhoto}
        <span className={styles.status}></span>
      </Link>
    </div>
  );
}