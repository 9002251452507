import React, { useState, FormEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';

import logoBranco from '../images/logo_branco.svg';
import { api } from '../services/api';
import { isAuthenticated } from '../services/isAuthenticated';

import styles from '../styles/pages/Login.module.css';

export function Login() {

  const history = useHistory();

  const [loginButton, setLoginButton] = useState(
    <button className={styles.button} type="submit" disabled>Login</button>
  );

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function redirect() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated() ? history.push('/app') : false;
  };

  redirect();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    await api.post('login', {
      username,
      passwrd: password
    }).then((res: any) => {
      localStorage.setItem('token', res.data.token);
      window.location.reload();
      /* history.push('/app'); */
    }).catch(() => {
      alert('Erro no login');
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles['login-text']}>
        <div className={styles.logo}>
          <img src={logoBranco} alt="Logo Meety" />
        </div>
        <h1>Bem-vindo de volta!</h1>
      </div>

      <div className={styles['login-form']}>
        <button type="button" onClick={() => { history.push('/'); }}>
          < HiArrowLeft />
          Voltar
        </button>
        <div className={styles.form}>
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>
            <div className={styles.inputGroup}>
              <input type="text" id="username" value={username} onChange={(e) => {
                setUsername(e.target.value);
                e.target.value && password ? setLoginButton(
                  <button className={styles.button} type="submit">Login</button>
                ) : setLoginButton(
                  <button className={styles.button} type="submit" disabled>Login</button>
                );
              }} placeholder="Username" />

              <input type="password" id="password" value={password} onChange={(e) => {
                setPassword(e.target.value);

                username && e.target.value ? setLoginButton(
                  <button className={styles.button} type="submit">Login</button>
                ) : setLoginButton(
                  <button className={styles.button} type="submit" disabled>Login</button>
                );

              }} placeholder="Password" />

              <Link to="/lost-password">
                Perdeste a tua palavra-passe?
              </Link>
            </div>

            <div className={`${styles.inputGroup} ${styles.buttons}`}>
              <span>
                Não tens uma conta? <Link to="/signup">Cria aqui!</Link>
              </span>

              {loginButton}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}