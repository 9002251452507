import { createContext, ReactNode, useEffect, useState } from 'react';
import { apiWithHeader } from '../services/api';

interface UserProviderProps {
  children: ReactNode;
}

interface UserContextData {
  id: string;
  username: string;
  realname: string;
  avatar: {
    id: string;
    url: string;
  }
  isOpened: boolean;
  isModalOpened: boolean;
  isSoonModalOpened: boolean;
  gridTemplate: string;
  hobbies_number: number;
  followers_number: number;
  followings_number: number;
  posts_number: number;
  handleToggleSidebar: () => void;
  handleTogglePostModal: () => void;
  handleToggleSoonModal: () => void;
}

/* interface UserDataProps {
  id: string,
  username: string,
  realname: string,
  gender: string,
  avatar: string,
}  */

export const UserContext = createContext({} as UserContextData);

export function UserProvider({ children }: UserProviderProps) {

  const [userData, setUserData] = useState<any>({});
  const [isOpened, setIsOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSoonModalOpened, setSoonIsModalOpened] = useState(false);
  const [gridTemplate, setGridTemplate] = useState('10fr');

  function handleToggleSidebar() {
    if (isOpened) {
      setIsOpened(false);
      setGridTemplate('10fr');
    } else {
      setIsOpened(true);
      setGridTemplate('10fr 5fr');
    }
  }

  function handleTogglePostModal() {
    if (isModalOpened) {
      setIsModalOpened(false);
    } else {
      setIsModalOpened(true);
    }
  }

  function handleToggleSoonModal() {
    if (isSoonModalOpened) {
      setSoonIsModalOpened(false);
    } else {
      setSoonIsModalOpened(true);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await apiWithHeader.get('me').then(userData => {
        setUserData(userData.data.data);
      }).catch(err => {
        localStorage.removeItem('token');
      });
    }

    fetchData();
  }, []);

  const { id, username, realname, avatar, hobbies_number, followers_number, followings_number, posts_number } = userData;

  return (
    <UserContext.Provider value={{
      id,
      username,
      realname,
      avatar,
      isOpened,
      isModalOpened,
      isSoonModalOpened,
      gridTemplate,
      hobbies_number,
      followers_number,
      followings_number,
      posts_number,
      handleToggleSidebar,
      handleTogglePostModal,
      handleToggleSoonModal
    }}>
      {children}
    </UserContext.Provider>
  )
}

