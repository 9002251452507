import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://api.meety.pt:8012',
});

export const apiWithHeader = axios.create({
    baseURL: 'https://api.meety.pt:8012',
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
});