import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './components/PrivateRoute';

import { Landing } from './pages/Landing';
import { Login } from './pages/Login';
import { App } from './pages/app/App';
import { Logout } from './pages/Logout';
import { SignUp } from './pages/SignUp';
import { Chat } from './pages/app/Chat';
import { Me } from './pages/app/Me';
import { Profile } from './pages/app/Profile';
/* import { Channel } from './pages/app/Channel'; */


function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/logout" exact component={Logout} />

        <PrivateRoute path='/app' exact component={App} />
        <PrivateRoute path='/app/chat' exact component={Chat} />
        <PrivateRoute path='/app/me' exact component={Me} />
        <PrivateRoute path='/app/profile' exact component={Me} />
        <PrivateRoute path='/app/profile/:userId' exact component={Profile} />
        {/* <PrivateRoute path='/app/channel/:channelId' exact component={Channel} /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;