import './styles/global.css';

import Routes from './routes';

import { UserProvider } from './contexts/UserContext';
import React from 'react';

function App() {
  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
}

export default App;
