import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';

import { UserContext } from '../../contexts/UserContext';

import { Menu } from '../../components/app/Menu';
import { Sidebar } from '../../components/app/Sidebar';
import { Header } from '../../components/app/Header';
/* import soundFile from '../../audios/new-message.mp3'; */

import { RiErrorWarningLine, RiSendPlane2Fill } from 'react-icons/ri';

import styles from '../../styles/pages/app/Chat.module.css';

import logo from '../../images/meety_icon_com_fundo.png';
import { Modal } from '../../components/app/Modal';
import { IoClose } from 'react-icons/io5';

const socket = io('https://api.meety.pt:8012');

export function Chat() {
  const { id, username, avatar, isOpened, gridTemplate, isSoonModalOpened, handleToggleSoonModal } = useContext(UserContext);
  const [message, setMessage] = useState('' as any);
  const [messages, setMessages] = useState([] as any);
  let { channelId } = useParams() as any;

  const AlwaysScrollToBottom = () => {
    const elementRef:any = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  useEffect((): any => {
    function handleNewMessage(newMessage: any) {
      setMessages([...messages, newMessage]);
    }

    socket.on('chat.message', handleNewMessage);

    

    /* if (messages.length > 0) {
      const audio = new Audio(soundFile);
      audio.play();
    } */
    return () => socket.off('chat.message', handleNewMessage);
  }, [messages]);

  function handleFormSubmit(event: any) {
    event.preventDefault();
    if (message.trim()) {
      socket.emit('chat.message', {
        socket_id: socket.id,
        channel_id: channelId,
        author: {
          id: id,
          username: username,
          avatar: avatar.url,
        },
        message,
        time: new Date()
      });
      setMessage('');
    }
  }

  function handleInputChange(event: any) {
    setMessage(event.target.value);
  }


  return (
    <div className={styles.app} style={{ gridTemplateColumns: gridTemplate }}>
      <Menu />

      <Header />

      {isSoonModalOpened && (
        <Modal title="Brevemente">
          <div className={styles.closeModal} onClick={handleToggleSoonModal}>
            <IoClose size="2rem" color="#ff0033" />
          </div>
          <RiErrorWarningLine size="4rem" color="var(--green)" />
        </Modal>
      )}

      <div className={styles.channelContainer}>
        <div className={styles.chatBox}>
          <img src={logo} alt="Logo" />
          <div className={styles.chatText}>
            <span>Chat Global da Meety</span>
          </div>
        </div>
        <ul id="messages" className={styles.list}>
          {messages.map((message: any, index: any) => {
            return (
              <li key={index} className={message.author.id === id ? `${styles.listItemMine}` : `${styles.listItem}`} >
                {message.author.id !== id && (
                  <span>
                    <img src={message.author.avatar} className={styles.avatar} alt={message.author.username} />
                    <span style={{ color: 'var(--grey)', fontSize: '0.8rem' }}>@{message.author.username}</span>
                  </span>
                )}
                <span className={message.author.id === id ? `${styles.messageMine}` : `${styles.messageOther}`}>
                  {message.message}
                </span>
              </li>
            )
          })}
          <AlwaysScrollToBottom />
        </ul>

        <form className={styles.form} onSubmit={handleFormSubmit}>
          <input
            className={styles.formField}
            placeholder="Escreva uma mensagem"
            type="text"
            onChange={handleInputChange}
            value={message}
            maxLength={2000}
          />
          <button type="submit" className={styles.submitBtn}>
            <RiSendPlane2Fill size="1.5rem" color="var(--blue)" />
          </button>
        </form>
      </div>

      {isOpened && <Sidebar />}
    </div>
  );
}