import logo from '../../images/logo.svg';

import styles from '../../styles/components/app/Header.module.css';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

export function Header() {
  return (
    <div className={styles.headerMobileContainer}>
      <img src={logo} alt="Logo" />

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '3.14rem'
      }}>
        <Link to="/logout">
          <FiLogOut size="1.9rem" color="#94A2B0" />
        </Link>
      </div>
    </div>
  )
}