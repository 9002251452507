import React, { useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Menu } from '../components/Menu';
import { Footer } from '../components/Footer';

import styles from '../styles/pages/Landing.module.css';

import laptop from '../images/laptop.svg';
import smartphone from '../images/smartphone.svg';
import like from '../images/like.svg';
import curiosities from '../images/curiosities.svg';
import aba from '../images/aba.svg';
import chat from '../images/chat.svg';
import calls from '../images/calls.svg';
import group from '../images/group.svg';
import friends from '../images/friends.svg';
import profile from '../images/profile.svg';
import games from '../images/games.svg';
import { isAuthenticated } from '../services/isAuthenticated';

export function Landing() {

  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated() ? history.push('/app') : false;
  }, [history]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Menu />

        <div className={styles['header-container']}>
          <div className={styles.text}>
            <h1>Share. Find. Meet.</h1>
            <p>
              A nova rede social que te vai permitir partilhar
              o que mais gostas, enviar mensagens, conhecer
              novas pessoas e acima de tudo garantir-te
              muitos minutos de pura diversão!
          </p>
            <Link to="/login"> Entra neste novo mundo </Link>
          </div>
          <div className={styles.images}>
            <img src={laptop} alt="Laptop" />
            <img src={smartphone} alt="Smartphone" />
          </div>
        </div>
      </div>

      <section className={styles.blue}>
        <h2>Partilha com os outros</h2>
        <h3>Aquilo que tu mais gostas.</h3>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={games} alt="Hobbies" />
            <div className={styles['card-container']}>
              <p className={styles.title}>Tecnologia? Moda? Desporto?</p>
              <p className={styles.description}>
                Tudo o que quiseres partilhar
                é valido! Desde que respeites os
                padrões da comunidade. 😀
              </p>
            </div>
          </div>
          
          <div className={styles.card}>
          <img src={like} alt="Like"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Gostos personalizados!</p>
              <p className={styles.description}>
                Acabaram-se os posts mais
                aborrecidos! Tudo o que encontras aqui é 100% feito para ti. 🎲
              </p>
            </div>
          </div>

          <div className={styles.card}>
            <img src={curiosities} alt="Curiosidades"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Curiosidades sobre ti? Partilha!</p>
              <p className={styles.description}>
                Com a nova funcionalidade "Um
                pouco sobre mim" podes contar
                tudo aos teus seguidores. 😎
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.white}>
        <h2>Encontra outras pessoas</h2>
        <h3>Com os mesmos interesses que tu.</h3>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={aba} alt="Aba"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Uma aba a pensar em ti!</p>
              <p className={styles.description}>
              Agora tens um lugar onde podes
              encontrar outras pessoas com
              os mesmos interesses que tu. 😄
              </p>
            </div>
          </div>
          
          <div className={styles.card}>
            <img src={friends} alt="Friends"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Amigos com os mesmos interesses!</p>
              <p className={styles.description}>
                A partir de agora podes
                procurar por pessoas que
                tenham os meus gostos do que tu! 😋
              </p>
            </div>
          </div>

          <div className={styles.card}>
          <img src={profile} alt="Profile"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Um perfil 100% personalizado!</p>
              <p className={styles.description}>
                E que tal um lugar onde possas escrever
                quais são os teus interesses? No teu perfil
                isso é possível!! 💻
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.blue}>
        <h2>Conhece-as</h2>
        <h3>Com as formas mais divertidas.</h3>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={chat} alt="Chat"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Incríveis formas de comunicação!</p>
              <p className={styles.description}>
                Mensagens, áudios, gifs, emojis e muito mais
                no chat desta rede social revolucionária! 🎧
              </p>
            </div>
          </div>
          
          <div className={styles.card}>
            <img src={group} alt="Group"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Privado ou em grupo?</p>
              <p className={styles.description}>
                Podes falar com todos os teus amigos ou só com aquele
                que queres partilhar aqueles segredos! 🤫
              </p>
            </div>
          </div>

          <div className={styles.card}>
          <img src={calls} alt="Calls"/>
            <div className={styles['card-container']}>
              <p className={styles.title}>Videochamadas engraçadas!</p>
              <p className={styles.description}>
                Diverte-te com os filtros engraçados
                que as videochamadas têm! Vão
                ser muitas horas de riso! 🤣
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles["rounded-card"]}>
        <p>Teste</p>
      </section> */}

      <Footer />
    </div>
  );
}