import React from 'react';

import '../styles/components/footer.css';

export function Footer() {
  
  return (
    <div id="footer" className="footer">
      <p>© Meety | Todos os direitos reservados</p>
    </div>
  );
}