import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';

import { Menu } from '../../components/app/Menu';
import { Stories } from '../../components/app/Stories';
import { Post } from '../../components/app/Post';
import { Header } from '../../components/app/Header';
import { Modal } from '../../components/app/Modal';

import { Sidebar } from '../../components/app/Sidebar';
import { HiOutlinePlusCircle, HiSearch } from 'react-icons/hi';

import styles from '../../styles/pages/app/App.module.css';
import { apiWithHeader } from '../../services/api';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

export function App() {
  const { realname, isOpened, gridTemplate, isModalOpened, isSoonModalOpened, handleTogglePostModal, handleToggleSoonModal } = useContext(UserContext);

  const [posts, setPosts] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [search, setSearch] = useState("");
  const [content, setContent] = useState("");
  const [usersSearched, setUsersSearched] = useState([] as any);
  const [image, setImage] = useState<File[]>([]);
  const [previewImage, setPreviewImage] = useState<string[]>([]);

  const [submitButton, setSubmitButton] = useState(
    <button className={styles.button} type="submit" disabled>Criar Post</button>
  );

  useEffect(() => {
    apiWithHeader.get('posts/users').then((res: any) => {
      setPosts(res.data.postsArr);
    });
  }, []);

  useEffect(() => {
    content && image.length > 0 ? setSubmitButton(
      <button className={styles.button} type="submit">Criar Post</button>
    ) : setSubmitButton(
      <button className={styles.button} type="submit" disabled>Criar Post</button>
    );
  }, [content, image]);

  function handleSelectImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;
    const selectedImage = Array.from(event.target.files);
    setImage(selectedImage);

    const selectedImagePreview = selectedImage.map(image => {
      return URL.createObjectURL(image);
    });

    setPreviewImage(selectedImagePreview);
  }

  async function handleCreatePost(event: FormEvent) {
    event.preventDefault();

    const data = new FormData();

    const postImage = image[0];

    data.append('content', content);
    data.append('has_file', "1");
    data.append('files', postImage);

    await apiWithHeader.post('posts', data).then((res: any) => {
      alert("Post introduzido com sucesso!");
      apiWithHeader.get('posts/users').then((res: any) => {
        setPosts(res.data.postsArr);
      });
    }).catch(() => {
      alert('Erro a introduzir o post!');
    });
  }

  useEffect(() => {
    if (realname) {
      setFirstName(realname.split(" ")[0])
    }

  }, [realname]);

  useEffect(() => {
    if (search) {
      apiWithHeader.get(`users?s=${search}`).then((res: any) => {
        setUsersSearched(res.data);
      });
    } else {
      setUsersSearched("");
    }
  }, [search]);

  return (
    <div className={styles.app} style={{ gridTemplateColumns: gridTemplate }}>
      <Menu />

      <Header />

      {isSoonModalOpened && (
        <Modal title="Brevemente">
          <div className={styles.closeModal} onClick={handleToggleSoonModal}>
            <IoClose size="2rem" color="#ff0033" />
          </div>
          <RiErrorWarningLine size="4rem" color="var(--green)" />
        </Modal>
      )}

      {isModalOpened && (
        <Modal title="Novo Post">
          <div className={styles.closeModal} onClick={handleTogglePostModal}>
            <IoClose size="2rem" color="#ff0033" />
          </div>
          <form className={styles.postForm} action="" onSubmit={handleCreatePost}>
            <div className={styles.inputGroup}>
              <label htmlFor="">Conteúdo</label>
              <input
                type="text"
                value={content}
                onChange={(e) => {
                  setContent(e.target.value)
                }}

              />

            </div>
            <div className={`${styles.inputGroup} ${styles.imageInput}`}>
              {image.length === 0 ? (
                <label htmlFor="image[]" className={styles.image}>
                  <FiPlus size="2.34rem" color="#368bff" />
                </label>
              ) : (
                previewImage.map((image: any, index: any) => {
                  return (
                    <div key={index} className={styles.imageContainer}>
                      <img className={styles.image} key={image} src={image} alt={realname} />
                      <button type="button" className={styles.imageCloseBtn} onClick={() => { setImage([]) }} >
                        <IoClose size="2rem" color="#ff0033" />
                      </button>
                    </div>
                  )
                })
              )}

              {image.length === 0 && (
                <input multiple type="file" name="image[]" id="image[]" onChange={handleSelectImage} />
              )
              }
            </div>
            {submitButton}
          </form>
        </Modal>
      )}

      <div className={styles.appContainer}>
        <div className={styles.moreBtn} onClick={handleTogglePostModal}>
          <HiOutlinePlusCircle size="2.06rem" color="#fff" />
        </div>
        <div className={styles.header}>
          <h1>
            <span>Olá, </span>
            <span>{firstName}!</span>
          </h1>

          <div className={styles.searchContainer}>
            <div className={styles.search}>
              <HiSearch size="1.9rem" color="var(--grey)" />
              <input
                type="text"
                placeholder="Pesquisar"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>

            {search && (
              usersSearched.length > 0 ? (
                <div className={styles.searchFieldContainer}>
                  {usersSearched.map((user: any) => {
                    return (
                      <Link to={`/app/profile/${user.id}`} key={user.id} className={styles.searchFields}>
                        <img src={user.avatar.url} alt="" />
                        <div className={styles.searchFieldText}>
                          <span>{user.realname}</span>
                          <span>@{user.username}</span>
                        </div>
                      </Link>
                    )
                  })}

                </div>
              ) : (
                <div className={styles.searchFieldContainer}>
                  <span style={{ color: 'var(--grey)' }}>Sem resultados!</span>
                </div>
              )

            )}


          </div>
        </div>

        <Stories />

        <div className={styles.feedContainer}>
          <div className={styles.feedHeader}>
            <h2>Feed</h2>

            <div className={styles.feedOptions}>
              <span className={styles.selected}>Pessoas</span>
              <span>Hobbies</span>
            </div>
          </div>

          <div className={styles.posts}>

            {posts.map((post: any) => {
              return (
                <Post
                  key={post.id}
                  author={post.author}
                  comments={21}
                  likes={post.likes}
                  content={post.content}
                  image={post.file_url}
                />
              )
            })}

          </div>
        </div>
      </div>

      {isOpened && <Sidebar />}
    </div>
  );
}