import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import styles from '../styles/pages/SignUp.module.css';

import { IoMdMale, IoMdFemale } from 'react-icons/io';
import { IoMaleFemaleSharp, IoClose } from 'react-icons/io5';
import { FiPlus } from 'react-icons/fi';
import { HiOutlineXCircle, HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import { api } from '../services/api';

export function SignUp() {

  const [registerButton, setRegisterButton] = useState(
    <button className={styles.button} type="submit" disabled>Registar</button>
  );

  const [gender, setGender] = useState('');
  const [realname, setRealname] = useState('');
  const [username, setUsername] = useState('');
  const [birth, setBirth] = useState('');
  const [correctAge, setCorrectAge] = useState(false);
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [avatar, setAvatar] = useState<File[]>([]);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState<string[]>([]);

  useEffect(() => {
    gender && realname && username && birth && country && phoneNumber && password && passwordConfirm && passwordMatch && usernameAvailable && avatar.length > 0 ? setRegisterButton(
      <button className={styles.button} type="submit">Registar</button>
    ) : setRegisterButton(
      <button className={styles.button} type="submit" disabled>Registar</button>
    );
  }, [gender, realname, username, birth, country, email, phoneNumber, password, passwordConfirm, passwordMatch, usernameAvailable, avatar]);

  useEffect(() => {
    api.get(`users?username=${username}`).then((res: any) => {
      if (res.data.length === 0) {
        setUsernameAvailable(true);
      } else {
        setUsernameAvailable(false);
      }
    })
  }, [username]);

  useEffect(() => {
    if (password === passwordConfirm) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

  }, [password, passwordConfirm]);

  useEffect(() => {
    if (!birth) return;
    const currentDate = new Date().getTime();
    const birthInMs = new Date(birth).getTime();
    const difference = currentDate - birthInMs;
    const userAge = Math.floor(difference / 1000 / 60 / 60 / 24 / 365);

    if (userAge >= 13) {
      setCorrectAge(true);
    } else {
      setCorrectAge(false);
    }
  }, [birth]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = new FormData();

    const avatarImage = avatar[0];

    data.append('gender', gender);
    data.append('realname', realname);
    data.append('username', username);
    data.append('birth', birth);
    data.append('country', country);
    data.append('email', email);
    data.append('phone_number', phoneNumber);
    data.append('passwrd', password);
    data.append('avatar', avatarImage);
    /* data.append('passwrdConfirm', passwordConfirm); */

    await api.post('register', data).then((res: any) => {
      localStorage.setItem('token', res.data.token);
      window.location.reload();
    }).catch(() => {
      alert('Erro no Registo');
    });
  }

  function handleSelectImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;
    const selectedAvatar = Array.from(event.target.files);
    setAvatar(selectedAvatar);

    const selectedAvatarPreview = selectedAvatar.map(avatar => {
      return URL.createObjectURL(avatar);
    });

    setPreviewAvatar(selectedAvatarPreview);
  }

  return (
    <div className={styles.signupContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.signup}>
          <h1>Cria a tua nova conta agora!</h1>
          <section className={styles.genderSection}>
            <p>Seleciona o teu género.</p>
            <div className={styles.inputs}>
              <div className={styles.inputGroup}>
                <input
                  type="radio"
                  id="masculino"
                  name="gender"
                  value={gender}
                  onChange={(e) => {
                    setGender("masculino")
                  }}
                />
                <label htmlFor="masculino">
                  <IoMdMale size="6rem" />
                  Masculino
              </label>
              </div>

              <div className={styles.inputGroup}>
                <input
                  type="radio"
                  id="feminino"
                  name="gender"
                  value={gender}
                  onChange={(e) => {
                    setGender("feminino")
                  }}
                />
                <label htmlFor="feminino">
                  <IoMdFemale size="6rem" />
                  Feminino
              </label>
              </div>
              <div className={styles.inputGroup}>
                <input
                  type="radio"
                  id="outro"
                  name="gender"
                  value={gender}
                  onChange={(e) => {
                    setGender("outro")
                  }}
                />
                <label htmlFor="outro">
                  <IoMaleFemaleSharp size="6rem" />
                  Prefiro não dizer
                </label>
              </div>
            </div>
          </section>

          <section className={styles.personalData}>
            <p>Completa os teus dados pessoais.</p>
            <div className={styles.inputGroupHorizontal}>
              <div className={styles.inputGroupHorizontalContainer}>
                <div className={styles.inputGroup}>
                  <label htmlFor="realname">Nome</label>
                  <input
                    type="text"
                    id="realname"
                    name="realname"
                    placeholder="Nome"
                    value={realname}
                    onChange={(e) => {
                      setRealname(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="birth">Data de nascimento</label>
              <input
                type="date"
                id="birth"
                name="birth"
                placeholder="Data de Nascimento"
                value={birth}
                onChange={(e) => {
                  setBirth(e.target.value);
                }}
              />
              {birth && !correctAge && (<small><HiOutlineExclamationCircle size="1.9rem" /> Precisas de ter mais de 13 anos.</small>)}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="country">País</label>
              <input
                type="text"
                id="country"
                name="country"
                placeholder="País"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>
          </section>

          <section className={styles.personalData}>
            <p>Completa os teus dados pessoais.</p>
            <div className={styles.inputGroupHorizontal}>
              <div className={styles.inputGroupHorizontalContainer}>
                <div className={styles.inputGroup}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="phone_number">Número de Telemóvel</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    placeholder="Número de Telemóvel"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.inputGroupHorizontal}>
              <div className={styles.inputGroupHorizontalContainer}>
                <div className={styles.inputGroup}>
                  <label htmlFor="password">Palavra-passe</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Palavra-passe"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="confirm-password">Confirme palavra-passe</label>
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                    placeholder="Confirme a palavra-passe"
                    value={passwordConfirm}
                    onChange={(e) => {
                      setPasswordConfirm(e.target.value);
                    }}
                  />
                </div>
              </div>
              {passwordConfirm && !passwordMatch && (<small><HiOutlineExclamationCircle size="1.9rem" /> As palavra-passes não coincidem!</small>)}
            </div>

          </section>
        </div>

        <div className={styles.profile}>
          <h1>O meu perfil</h1>
          <div className={styles.avatarInput}>
            {avatar.length === 0 ? (
              <label htmlFor="avatar[]" className={styles.avatar}>
                <FiPlus size="2.34rem" color="#368bff" />
              </label>
            ) : (
                previewAvatar.map(avatar => {
                  return (
                    <div className={styles.imageContainer}>
                      <img className={styles.avatar} key={avatar} src={avatar} alt={realname} />
                      <button type="button" className={styles.avatarCloseBtn} onClick={() => { setAvatar([]) }} >
                        <IoClose size="2rem" color="#ff0033" />
                      </button>
                    </div>
                  )
                })
              )}

            {avatar.length === 0 && (
              <input multiple type="file" name="avatar[]" id="avatar[]" onChange={handleSelectImage} />
            )
            }

            <p className={styles.realNameText}>{realname}</p>
            {username ? usernameAvailable ? (<p className={styles.userNameText}>@{username} <HiOutlineCheckCircle size="1.9rem" color="#36C699" /> </p>) : (<p className={styles.userNameText}>@{username} <HiOutlineXCircle size="1.9rem" color="#ff0033" /> </p>) : (<></>)}
          </div>

          {registerButton}
        </div>
      </form>
    </div>
  );
}