import styles from '../../styles/components/app/Modal.module.css';

interface ModalProps {
  title: string;
  children: any;
}

export function Modal(props: ModalProps) {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <h1 className={styles.modalTitle}>{props.title}</h1>
        {props.children}
      </div>
    </div>
  )
}