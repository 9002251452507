import axios from 'axios';
import { useEffect, useState } from 'react';
import styles from '../../styles/components/app/Stories.module.css';

export function Stories() {

  const [randomUsers, setRandomUsers] = useState([] as any);

  useEffect(() => {
    axios.get('https://randomuser.me/api/?results=100&inc=name,picture').then(res => {
      setRandomUsers(res.data.results);
    });
  }, []);

  return (
    <div id="stories" className={styles.storiesContainer} >
      <h2>
        <span>Histórias para ti</span>
        {/* <span>para ti</span> */}
      </h2>

      <div className={styles.stories}>
        <div className={styles.overlay}>
          <span>Disponível Brevemente</span>
        </div>
        <div className={styles.storyContainer}>

          {randomUsers.map((user: any) => {
            return (
            <div key={randomUsers.indexOf(user)} className={styles.story}>
              <img src={user.picture.medium} alt="Username" />
              <span>@{user.name.first}</span>
            </div>
            )
          })}
        </div>


      </div>
    </div>
  )
}