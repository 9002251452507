import React, { useEffect, useState } from 'react';

import '../styles/components/menu.css';

import logo from '../images/logo.svg';

import { Link } from 'react-router-dom';

import { FaBars, FaTimes } from 'react-icons/fa';

export function Menu() {
  const [button, setButton] = useState(<FaBars size="2rem" color="#368bff" />);
  const [isActive, setIsActive] = useState(false);

  function toggleMenu() {
    document.getElementById("pages")?.classList.toggle("active");

    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }

  useEffect(() => {
    if (isActive) {
      setButton(<FaTimes size="2rem" color="#368bff" />);
    } else {
      setButton(<FaBars size="2rem" color="#368bff" />);
    }
  }, [isActive]);

  return (
    <div id="menu" className="menu">
      <Link to="/">
        <img src={logo} alt="Meety logo" />
      </Link>
      <div className="pages">
        <button id="menuBtn" onClick={toggleMenu}>
          {button}
        </button>
        <ul id="pages">
          {/* <li><Link to="/functionalities">Funcionalidades</Link></li>
          <li><Link to="/privacy-and-security">Privacidade e Segurança</Link></li>
          <li><Link to="/work-with-us">Trabalha connosco</Link></li> */}
          <li><Link to="/signup">Regista-te</Link></li>
          <li className="login"><Link to="/login">Login</Link></li>
        </ul>
      </div>
    </div>
  );
}