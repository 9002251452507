import { useHistory } from 'react-router-dom';

export function Logout() {
  localStorage.removeItem('token');
  const history = useHistory();
  history.push('/login');

  return (
    <></>
  );
}